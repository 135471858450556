import axios from 'axios' // 引用axios
// import {MessageBox, Message} from 'element-ui'
import Qs from 'qs'
import router from '@/router'
import cache from "./cache"
import config from "./config";
/* eslint-disable no-alert, no-console, no-empty,no-unused-vars */
// axios 配置
// 设置响应超时
axios.defaults.timeout = 60000
// axios.defaults.baseURL = 'https://easy-mock.com/mock/' // 这是调用数据接口,公共接口url+调用接口名
let httpUrl = window.location.host;
// 这是调用数据接口,公共接口url+调用接口名
axios.defaults.uploadURL = config.upload_url; // 这是调用数据接口,公共接口url+调用接口名
if (httpUrl.indexOf("localhost") || httpUrl.indexOf("192.168")) {
    axios.defaults.baseURL = config.test_url; // 这是调用数据接口,公共接口url+调用接口名
} else {
    axios.defaults.baseURL = config.release_url; // 这是调用数据接口,公共接口url+调用接口名
}
// http request 拦截器，通过这个，我们就可以把token传到后台
axios.interceptors.request.use(
    config => {
        let user_token = cache.get('token');
        if (config.method == 'get') {
            config.headers = {
                'token': user_token,
            }
            config.data = Qs.stringify(config.data)
            return config
        } else {
            config.headers = {
                //multipart/form-data
                //
                'Content-Type': config.headers.content_type, // 设置跨域头部
                'token': user_token,
            }
            // config.data = Qs.stringify(config.data)
            return config
        }
    },
    err => {
        return Promise.reject(err)
    }
)

// http response 拦截器
axios.interceptors.response.use(response => {
        // console.log(response);return ;
        if (response.status === 200) {
            // 成功
            let returnCode = response.data.code;
            // returnCode=401;
            if (returnCode > 10000 && returnCode < 20000) {
                // console.log('成功', response)
                Message.success(response.data.msg)
            } else if (returnCode >= 20000 && returnCode < 30000) {
                // 只弹窗，不操作
                // console.log('失败1', response)
                Message.error(response.data.msg)
            } else if (returnCode >= 30000 && returnCode < 40000) {
                // 只弹窗，点击跳到登入页
                // localStorage.removeItem('userInfo')

            }
        } else {
            console.log("登陆超时，请重新登录")

            return 'error';
        }
        if (response.status == 401) {
            console.log("登陆超时，请重新登录")

            return 'error';
        }
        if (response.status == 99) {
           console.log("登陆超时，请重新登录")
            return 'error';
        }
        return response
    }, error => {
        if (
            error.toString().trim() ===
            "TypeError: Cannot read property 'cancelToken' of null"
        ) {
            console.log("会话凭证失效，可以取消继续留在该页面，或者重新登录")
        }
        if (error.toString().trim() === 'Error: Network Error') {
            console.log("网络请求异常，请稍后重试', '出错了");
        }
        return Promise.reject(error.response.data)
    }
)

export default axios


/**
 * fetch 请求方法
 * @param url
 * @param params
 * @returns {Promise}
 */
export function get(url, params = {}) {
    //params.token=cache.get('token');
    return new Promise((resolve, reject) => {
        axios.get(axios.defaults.baseURL + url, {
            params: params
        })
            .then(response => {
                resolve(response.data)
            })
            .catch(err => {
                if (err.code == 99) {
                    console.log("登陆超时，请重新登录");
                    return false;
                }
                reject(err)
            })
    })
}

/**
 * post 请求方法
 * @param url
 * @param data
 * @param config
 * @returns {Promise}
 */
export function post(url, data = {}, config = {}) {
    // data.token=cache.get('token');
    return new Promise((resolve, reject) => {
        axios.post(axios.defaults.baseURL + url, data, config).then(
            response => {
                // console.log(response.data.code)
                resolve(response.data)
            },
            err => {
                if (err.code == 99) {
                    console.log("登陆超时，请重新登录")
                    return false;
                }
                reject(err)
            }
        )
    })
}

/**
 * post 请求方法
 * @param url
 * @param data
 * @param config
 * @returns {Promise}
 */
export function upload_post(url, data = {}, config = {}) {
    let token = cache.get('token');
    return new Promise((resolve, reject) => {
        let formData = new FormData();
        formData.append('file', data);
        formData.append('token', token);

        let instance = axios.create({
            baseURL: axios.defaults.uploadURL,
            timeout: 60000,
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
        instance.post('', formData).then(
            response => {
                // console.log(response.data.code)
                resolve(response.data)
            },
            err => {
                if (err.code == 99) {
                    console.log("登陆超时，请重新登录")

                    return false;
                }
                reject(err)
            }
        )
    })
}

