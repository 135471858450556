import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'home',
    component: () => import( '../views/Index.vue')
    // component: () => import( '../views/Business.vue')

  },
  {
    path: '/active',
    name: 'active',
    component: () => import( '../views/Active.vue')
    // component: () => import( '../views/Business.vue')

  },
  // {
  //   path: '/apply/install',
  //   name: '/apply/install',
  //   component: () => import( '../views/ApplyInstall.vue'),
  //
  //
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
