import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import '@/assets/css/reset.css'
import '@/assets/css/base.css'

Vue.config.productionTip = false
import {get, post} from "./utils/http";
import cache from "./utils/cache";

Vue.prototype.$get = get;
Vue.prototype.$post = post;
Vue.prototype.$cache = cache;

//按需引入组件
import {
    Dialog, Input, MessageBox,
    Message, Form,
    FormItem,
    Carousel,
    CarouselItem
} from 'element-ui';
import 'lib-flexible/flexible.js'
import '@/utils/rem.js'

// Vue.component(Dialog.name, Dialog);
Vue.use(Dialog);
Vue.use(Input);
Vue.use(Form);
Vue.use(FormItem);
//走马灯
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.prototype.$elMsgbox = MessageBox;
Vue.prototype.$elAlert = MessageBox.alert;
Vue.prototype.$elConfirm = MessageBox.confirm;
Vue.prototype.$elPrompt = MessageBox.prompt;
Vue.prototype.$elMessage = Message;
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
