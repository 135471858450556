module.exports={
    /*
    * TODO nft 相关 上线生产环境需要配置
    * */
    // nft_office_owner:5,
    // server_token:'bf9d026d1b0213b784f2befdacf7dbfea4b7bd1a',
    // h5_url:'https://mobile.starmarvel.net',
    // upload_url:'https://res.mnft.vip/uploads.php?action=uploads',

    base_url:'',
    dev_url:'',
    // test_url:'http://localhost:7083',
    test_url:'https://centerapi.genkigo.net',
    release_url:'https://centerapi.genkigo.net',
    upload_url:'https://centerapi.genkigo.net/common/uploadFile',
    // test_url:'http://consoleapi-dev.genkigo.net',

    upload:{
        pic_ext:'png,jpeg,jpg,gif',
        // pic_size:'4194304',
        pic_size:'10485760',
        video_ext:'mp4,rmvb,avi,mkv',
        video_size:'20971520',
        threeD:'obj,mtl,png,jpeg,jpg',
    },
    // oss:{
    //     endpoint:'oss-cn-guangzhou.aliyuncs.com',
    //     region: 'https://img.mnft.vip',
    //     // region: 'oss-cn-guangzhou',
    //     accessKeyId: 'LTAI5tMZ91sfqb9QT9pBwYJc',
    //     accessKeySecret: 'B7NqcwaItY7w8olPLR7JlG2JMc75gJ',
    //     bucket: 'mnft-images'
    // },
    // cropper:{
    //     img: '',             //裁剪图片的地址
    //     outputSize: 1,       //裁剪生成图片的质量(可选0.1 - 1)
    //     outputType: 'jpeg',  //裁剪生成图片的格式（jpeg || png || webp）
    //     info: true,          //图片大小信息
    //     canScale: true,      //图片是否允许滚轮缩放
    //     autoCrop: true,      //是否默认生成截图框
    //     autoCropWidth: 236,  //默认生成截图框宽度
    //     autoCropHeight: 324, //默认生成截图框高度
    //     fixed: true,         //是否开启截图框宽高固定比例
    //     fixedNumber: [1, 1.38], //截图框的宽高比例
    //     full: false,         //false按原比例裁切图片，不失真
    //     fixedBox: true,      //固定截图框大小，不允许改变
    //     canMove: false,      //上传图片是否可以移动
    //     canMoveBox: true,    //截图框能否拖动
    //     original: false,     //上传图片按照原始比例渲染
    //     centerBox: false,    //截图框是否被限制在图片里面
    //     height: true,        //是否按照设备的dpr 输出等比例图片
    //     infoTrue: false,     //true为展示真实输出图片宽高，false展示看到的截图框宽高
    //     maxImgSize: 3000,    //限制图片最大宽度和高度
    //     enlarge: 1,          //图片根据截图框输出比例倍数
    //     mode: '236px 324px'  //图片默认渲染方式
    // }
}